/* eslint-disable keyword-spacing */
/* eslint-disable space-before-blocks */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable quotes */

import ScrollMagic from 'scrollmagic'
import { TweenMax, TimelineMax, Linear } from 'gsap'
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
import Cookies from 'js-cookie';

/*
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
*/


// Show animated element when JS is disabled

window.addEventListener("DOMContentLoaded", (event) => {

    var body = document.querySelector("body");
    body.classList.add('js-enabled');

    if (body.classList.contains('js-enabled')) { 
        var animatedBlocks = Array.prototype.slice.call(document.querySelectorAll('.animate-me'));
        var footerPin = document.querySelector('.CTA-footer');
        if(footerPin) {
            footerPin.classList.add('js-pin');
        }
       

        if (animatedBlocks.length) {
            animatedBlocks.forEach(function (animatedBlock) {
                animatedBlock.classList.add('smg-to-slide-up');
            })
        }
    }
});


import imagesLoaded from 'imagesloaded';


let smgController = false;
let smgScenes = [];

function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        let context = this,
            args = arguments,
            later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            },
            call_now = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (call_now) func.apply(context, args);
    };
}


function initScrollMagic() {
    if (smgController) return;
    smgController = new ScrollMagic.Controller();
    smgScenes.length = 0;

    // different cases

  var ctaFooter = document.querySelector(".cta-footer--scroll");

    if (ctaFooter && !ctaFooter.classList.contains('smg-disabled')) { // Temp solution

        // ctaFooter.classList.remove('smg-disabled');

        var duration = ctaFooter.offsetHeight;

        if (duration < window.innerHeight) {

      var scene = new ScrollMagic.Scene({ triggerElement: ".cta-footer--scroll", triggerHook: 1, duration: duration })
        .setPin(".CTA-footer--pin", { pushFollowers: false })
        // .addIndicators({ name: "1" })
        .addTo(smgController);

            smgScenes[0] = scene;
        }
        else {
            ctaFooter.classList.add('smg-disabled');
        }
    }

    if (document.querySelector(".hero")) {
        if (document.querySelector(".hero__backdrop")) {
            window.addEventListener('load', function () {
                document.querySelector(".hero__backdrop").classList.add('hero__backdrop--removed');
            })
        }

        var HeroParallax = new ScrollMagic.Scene({ triggerElement: ".hero", triggerHook: 0, duration: '100%' })
            .setTween(".hero__content", { css: { transform: "translateY(50%)" }, ease: Linear.easeNone })
            // .addIndicators({ name: "2" })
            .addTo(smgController);

        var HeroParallaxTitle = new ScrollMagic.Scene({ triggerElement: ".hero", triggerHook: 0, duration: 2000 })
            .setTween(".hero__video", { css: { transform: "translateY(500px)" }, ease: Linear.easeNone })
            // .addIndicators({ name: "3" })
            .addTo(smgController);
    }

    var interludes = Array.prototype.slice.call(document.querySelectorAll('.interlude'));

    if (interludes.length) {
        interludes.forEach(function (interlude) {
            // if (document.querySelector(".interlude")) {
            var interludeContent = interlude.querySelector('.interlude__content');
            var interludeVideo = interlude.querySelector('.interlude__video');

            var interludeParallax = new ScrollMagic.Scene({ triggerElement: interludeContent, triggerHook: 1, duration: '200%' })
                .setTween(interludeVideo, { css: { transform: "translateY(20vh)" }, ease: Linear.easeNone })
                // .addIndicators({ name: "interlude" })
                .addTo(smgController);
            // }
        })
    }

    // if(window.innerWidth > 900){
    //   imagesLoaded(document.querySelector('.grid-container img'), function (){
    //     console.log("Images are loaded")
    //       if (document.querySelector(".smg-pinned-faded-container")) {
    //         var pinnedFadedDuration = document.querySelector('.smg-pinned-faded-container').offsetHeight;

    //         var scene1 = new ScrollMagic.Scene({ triggerElement: ".smg-pinned-faded-container", triggerHook: .5, duration: pinnedFadedDuration })
    //           .setPin(".smg-pinned-faded-content", { pushFollowers: false })
    //           // .addIndicators({ name: "pinned" })
    //           .addTo(smgController);

    //         var scene2 = new ScrollMagic.Scene({ triggerElement: ".smg-pinned-faded-content", triggerHook: 0.45, duration: pinnedFadedDuration })
    //           .setClassToggle(".smg-pinned-faded-content header", "faded")
    //           // .addIndicators({ name: "faded" })
    //           .addTo(smgController);

    //         smgScenes[smgScenes.length] = scene1;
    //         smgScenes[smgScenes.length] = scene2;
    //       }
    //     })
    // }


    if (document.querySelector(".smg-pinned-faded-container")) {
        if (window.innerWidth > 900) {
            if (document.querySelectorAll('.grid-container')) {
                imagesLoaded(document.querySelectorAll('.grid-container img'), function () {

                    var pinnedFadedDuration = document.querySelector('.smg-pinned-faded-container').offsetHeight;

                    var scene1 = new ScrollMagic.Scene({ triggerElement: ".smg-pinned-faded-container", triggerHook: .5, duration: pinnedFadedDuration })
                        .setPin(".smg-pinned-faded-content", { pushFollowers: false })
                        // .addIndicators({ name: "pinned" })
                        .addTo(smgController);

                    var scene2 = new ScrollMagic.Scene({ triggerElement: ".smg-pinned-faded-content", triggerHook: 0.85, duration: pinnedFadedDuration })
                        .setClassToggle(".smg-pinned-faded-content header", "faded")
                        // .addIndicators({ name: "faded" })
                        .addTo(smgController);

                    smgScenes[smgScenes.length] = scene1;
                    smgScenes[smgScenes.length] = scene2;
                })
            }
        }
    }




    var toFadeIn = Array.prototype.slice.call(
        document.querySelectorAll('.smg-to-fade-in')
    );

    toFadeIn.forEach(function (item) {

        new ScrollMagic.Scene({ triggerElement: item.parentNode, triggerHook: .55, reverse: false })
            .setClassToggle(item, "smg-faded-in")
            // .addIndicators({ name: "fade" })
            .addTo(smgController);
    })

    var toSlideUp = Array.prototype.slice.call(
        document.querySelectorAll('.smg-to-slide-up')
    );

    toSlideUp.forEach(function (item) {
        new ScrollMagic.Scene({ triggerElement: item.parentNode, triggerHook: .75, reverse: false })
            .setClassToggle(item, "smg-slided-up")
            // .addIndicators({ name: "slide" })
            .addTo(smgController);
    })


    /** Line scroll */
    const lineScrollTl = new TimelineMax()
    const textImageFadeTl = new TimelineMax()
    const sectionDesc = document.querySelectorAll('.workbook-intro__description .section-description')

    lineScrollTl
        .to(".workbook-header__line", 7, { height: "100%" })
        .to(".workbook-header__sub-heading", 10, { css: { opacity: 1 } })

    textImageFadeTl
        .to(".workbook-intro__image img", .5, { css: { opacity: 1 } })
        .staggerTo(sectionDesc, .2, { css: { opacity: 1 }, stagger: 0.6 })

    //const lineScroll = new ScrollMagic.Scene({ triggerElement: ".workbook-header", triggerHook: 0, duration: "100%"})
    //  .setTween(lineScrollTl)
    //  .addTo(smgController);

    //const textImageFade = new ScrollMagic.Scene({triggerElement: ".workbook-intro", triggerHook: 0.3})
    //  .setTween(textImageFadeTl)
    //  .addTo(smgController)

}

function removeScrollmagic() {

    smgController.update(true);


    if (smgController) {
        smgController = smgController.destroy(true);

        // block halfway-through animations
        // (to check: might need resetting more values elsewhere)
        TweenMax.killAll();

        // remove any pin-spacers
        // $('.scrollmagic-pin-spacer').contents().unwrap();
        // remove inline styling
        // $('.pin-wrapper, .pin-wrapper img').attr('style', '');
    }
}

function resetScrollMagic() {
    removeScrollmagic();
    initScrollMagic();
}


window.addEventListener('DOMContentLoaded', initScrollMagic);
window.addEventListener("resize", debounce(resetScrollMagic, 500));





/*
  function setDurations() {
    console.log('resetting durations')
    if (smgScenes.length) {
      for (var k = 0, l = smgScenes.length; k < l; k++) {
        if (k === 1) { // this is the only one with different duration
          smgScenes[k].duration = document.querySelector(".cta-footer").offsetHeight;
        } else {
          if (document.querySelector('.smg-pinned-faded-container')) {
            smgScenes[k].duration = document.querySelector('.smg-pinned-faded-container').offsetHeight;
          }
        }
        smgScenes[k].update();
      }
    }
    smgController.update(true);
  }

  window.addEventListener("resize", debounce(setDurations, 500));
*/


function getTargetOffset(target) {
    const doc = document.documentElement;
    const box = target.getBoundingClientRect();
    const top = box.top + window.pageYOffset - doc.clientTop;
    const left = box.left + window.pageXOffset - doc.clientLeft;
    return { top: top, left: left };
}

function goToHash(hash) {
    const target = document.querySelector(hash);

    window.scrollTo({
        top: getTargetOffset(target).top,
        left: 0,
        behavior: "smooth"
    });
}

function goToNextSection(hash) {
    const target = document.querySelector(hash);
    window.scrollTo({
        top: getTargetOffset(target).top + target.offsetHeight,
        left: 0,
        behavior: "smooth"
    });
}

window.addEventListener('DOMContentLoaded', function () {

    //our people contact us trigger
    var contactTarget = document.querySelector('.cta-footer');
    var fakeTarget = document.querySelector('.page-footer-wrapper');
    var triggerContact = document.querySelector('.our-people-contact');

    if(triggerContact){
        triggerContact.addEventListener('click', function (e) {
            window.scrollTo({
                top: getTargetOffset(fakeTarget).top - contactTarget.offsetHeight,
                left: 0,
                behavior: "smooth"
            });
        })
    }


    // navigate to hash with a smooth scroll
    var goToHashLink = Array.prototype.slice.call(document.querySelectorAll('.go-to-hash'));

    goToHashLink.forEach(function (item) {
        item.addEventListener('click', function (e) {
            e.preventDefault();
            goToNextSection('.hero');
            console.log(item.hash);
        })
    })

    if (document.querySelector('.show-more')) {
        document.querySelector('.show-more__trigger button').addEventListener('click', function () {
            var showMoreBlock = this.parentNode.parentNode
            showMoreBlock.querySelector('.show-more__trigger').style.display = 'none';
            showMoreBlock.querySelector('.show-more__content').classList.add('show-more__content--displayed');
        })
    }


    // sharing blog posts
    var socialShare = Array.prototype.slice.call(document.querySelectorAll('.social-share'));

    if (socialShare.length) {
        socialShare.forEach(function (item) {
            item.addEventListener('click', function (e) {
                e.preventDefault();

                var w = 550,
                    h = 550,
                    x = (screen.width - w) / 2,
                    y = (screen.height - h) / 2;

                var popupOptions = 'width=' + w + ',height=' + h + ',left=' + x + ',top=' + y + ',toolbar=0,menubar=0,status=0,resizable=1';


                var
                    pageImage = document.querySelector('meta[property="og:image"]').getAttribute("content"),
                    pageSummary = document.querySelector('meta[property="og:description"]').getAttribute("content"),
                    pageUrl = document.querySelector('meta[property="og:url"]').getAttribute("content"),
                    pageTitle = document.querySelector('meta[property="og:title"]').getAttribute("content");

                if (this.classList.contains('social-share--facebook')) {
                    window.open('https://www.facebook.com/dialog/feed?app_id=184775355765685&amp;display=popup&amp;caption=' + pageTitle + '&amp;picture=' + pageImage + '&amp;name=' + pageTitle + '&amp;description=' + pageSummary + '&link=' + pageUrl + '&amp;redirect_uri=http://facebook.com', 'Facebook', popupOptions);
                }
                else if (this.classList.contains('social-share--twitter')) {
                    window.open('https://www.twitter.com/intent/tweet?text=' + pageTitle + '&url=' + pageUrl, 'Twitter', popupOptions);
                }
                else {
                    window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + pageUrl + '&title=' + pageTitle + '&summary=' + pageSummary, 'LinkedIn', popupOptions);
                }
            })
        })
    }

    if($( window ).width() < 1024) {
        setTimeout(function() {
            $('.navbar__list .navbar__list-item-wrapper').each(function(index, elem){
                
                let parent_link = $(elem).find('a').attr('href');
                let parent_text = $(elem).find('a span').text();

                $(elem).find('.navbar__list-item-dropdown').prepend("<li class='navbar__list-item-dropdown__item'><a href='"+ parent_link +"'>About "+ parent_text +"</a></li>")
            
            })

            $('.navbar__list-item-navbarlink--mobile').on('click', function(e){
                e.preventDefault();
                $(this).toggleClass('active-dropdown');
                $(this).parent().find('.navbar__list-item-dropdown').slideToggle();
            })
        }, 1000);
    }
    

});

window.addEventListener('DOMContentLoaded', function () {

    var ul = document.querySelector('.team-carousel-block--slider-carousel');

    if(ul) {

        for (var i = ul.children.length; i >= 0; i--) {
            ul.appendChild(ul.children[Math.random() * i | 0]);
        }
    
        $('.team-carousel-block--slider-carousel').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            variableWidth: false,
            infinite: true,
            arrows: true,
            prevArrow: $('.prev'),
            nextArrow: $('.next'),
            responsive: [{
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
            },
            ]
        });
    }

})

window.addEventListener('DOMContentLoaded', function () {
    console.log("Carousel loaded from old place")
    $('.slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        infinite: false,
        speed: 1500,
        arrows: true,
        prevArrow: $('.prev'),
        nextArrow: $('.next'),
        // responsive: [{
        //     breakpoint: 1600,
        //     settings: {
        //       slidesToShow: 3,
        //       slidesToScroll: 2
        //     }
        //   }, {
        //     breakpoint: 1250,
        //     settings: {
        //       slidesToShow: 2,
        //       slidesToScroll: 2
        //     }
        //   }, {
        //     breakpoint: 767,
        //     settings: {
        //       slidesToShow: 2,
        //       slidesToScroll: 2,
        //       dots: true
        //     }
        //   }, {
        //     breakpoint: 585,
        //     settings: {
        //       slidesToShow: 2,
        //       slidesToScroll: 1,
        //       dots: true
        //     }
        // }]
    });
})

window.onscroll = function (ev) {
    let bottomButton = document.querySelector('.back-to-top');
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        bottomButton.classList.add('show');
    } else {
        bottomButton.classList.remove('show');
    }
};


window.addEventListener('DOMContentLoaded', function () {

    document.querySelector('.back-to-top').addEventListener('click', function () {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    if($('.tinnovation-event-page')) {
       $('.tinnovation-event-page .about-founders--row .btn').on('click', function (e) {
            e.preventDefault();

            $([document.documentElement, document.body]).animate({
                scrollTop: $('.pipedrive-block').offset().top
            }, 2000);

        });

    }

    

    var cookiesMessages = {
        "denied": "Thank you for letting us know you do not want us to use cookies. You can always <a href='//www.thetin.net/home/cookies'>update your cookie settings</a> in our Cookies Policy page.",
        "accepted": "Thank you for accepting our Cookie Policy. You can change your cookie settings at any time, and read our <a href='//www.thetin.net/home/cookies'>Cookies Policy</a> for more information."
    },
        selectedCookieMessage = cookiesMessages.accepted,
        removeCookiesBarPanel = false;


    var cookiesBar = document.querySelector('#cookies-disclaimer');

    var cookiesPreference = Cookies.get('cookiesPreference');

    if (typeof cookiesPreference === 'undefined') {
        cookiesBar.classList.add('expanded');
    } else {
        if (cookiesPreference == 'accepted') {
            setupGoogleAnalytics()
        }
    }

    document.querySelector('#cookies-btn-denied').addEventListener('click', function () {
        Cookies.set('cookiesPreference', 'denied', { expires: 365 });
        selectedCookieMessage = cookiesMessages.denied;
        // shouldn't we remove GA coockies here?

        // remove GA cookies
        Cookies.remove('_ga');
        Cookies.remove('_gat');
        Cookies.remove('_gid');

        showFeedback();
    })

    document.querySelector('#cookies-btn-accepted').addEventListener('click', function () {
        Cookies.set('cookiesPreference', 'accepted', { expires: 365 });
        selectedCookieMessage = cookiesMessages.accepted;
        setupGoogleAnalytics();
        showFeedback()
    })

    function showFeedback() {
        cookiesBar.querySelector('.buttons-wrapper').classList.add('buttons-wrapper--hidden');

        cookiesBar.querySelector('.cookies-default-message').classList.add('cookies-default-message--hidden');
        var msg = document.createElement('p');
        msg.classList.add('cookies-final-message');
        msg.innerHTML = selectedCookieMessage;
        cookiesBar.querySelector('.flexed-cell:first-of-type').appendChild(msg);

        if (removeCookiesBarPanel) clearTimeout(removeCookiesBarPanel);
        removeCookiesBarPanel = setTimeout(function () { cookiesBar.classList.remove('expanded') }, 2000);
    }


    var updateButton = document.querySelector('.updateCookiesSettings');

    // this button is supposed to exist in the cookie policy page only
    if (updateButton) {

        updateButton.addEventListener('click', function () {

            // remove the cookie
            Cookies.remove('cookiesPreference', {}); ``

            // remove GA cookies
            Cookies.remove('_ga');
            Cookies.remove('_gat');
            Cookies.remove('_gid');

            // reset message in the unlikely chance you are on the same page
            if (cookiesBar.querySelector('.cookies-final-message')) cookiesBar.querySelector('.cookies-final-message').remove();

            cookiesBar.querySelector('.buttons-wrapper').classList.remove('buttons-wrapper--hidden');
            cookiesBar.querySelector('.cookies-default-message').classList.remove('cookies-default-message--hidden');
            cookiesBar.classList.add('expanded');

            // prevent closing the panel if it was scheduled to do so
            if (removeCookiesBarPanel) clearTimeout(removeCookiesBarPanel);

        })
    }


    function setupGoogleAnalytics() {
        (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments)
            }, i[r].l = 1 * new Date(); a = s.createElement(o),
                m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
        })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

        ga('create', 'UA-2206181-1', 'auto');
        ga('set', 'anonymizeIp', true);
        ga('send', 'pageview');
    }

    const button = document.querySelectorAll('.copy-insights');
    const articleURL = document.querySelectorAll('.article-url-copied');
    const socialButton = document.querySelector('.social-button');
    const socialIcons = document.querySelector('.social-icons-wrap');

    if (button) {

        Array.prototype.forEach.call(button, function (elem, index) {

            elem.onclick = () => {
                navigator.clipboard.writeText(window.location.href);
                articleURL[index].classList.add('show');

                setTimeout(function () {
                    articleURL[index].classList.remove('show');
                }, 2000);

            }
        });

    }

    if (socialButton) {
        socialButton.onclick = () => {
            socialButton.classList.add('hideme');
            socialIcons.classList.add('active');
        }
    }

})



